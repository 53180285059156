import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 101 101"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "5",
      d: "M34.65 75.922V44.48a1.98 1.98 0 0 1 .579-1.4l13.947-13.947a1.98 1.98 0 0 1 2.798 0l13.79 13.81c.37.37.578.873.578 1.398v31.578a1.976 1.976 0 0 1-1.976 1.977h-27.74a1.976 1.976 0 0 1-1.976-1.974z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "5",
      d: "M50.5 40.337c-9.384-6.895-14.971-10.105-13.308-16.879 1.755-7.134 9.021-8.679 13.308-5.231 4.287 3.447 10.263 11.531 9.782 19.228"
    }, null, -1),
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "2",
      d: "M50.5 44.046a3.71 3.71 0 1 0 0-7.421 3.71 3.71 0 0 0 0 7.421Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M43.192 62.658a1.56 1.56 0 0 1 0-1.764q.273-.31.968-.31h.174a3.4 3.4 0 0 1-.024-1.192h-.126a1.3 1.3 0 0 1-.968-.31 1.23 1.23 0 0 1-.298-.882 1.25 1.25 0 0 1 .287-.882 1.28 1.28 0 0 1 .98-.31h.546a8.5 8.5 0 0 1 1.256-2.511 8.8 8.8 0 0 1 1.95-1.96 9.14 9.14 0 0 1 5.413-1.74c.263 0 .526 0 .771.024.245.023.487.042.721.076a1.29 1.29 0 0 1 .968.526 1.32 1.32 0 0 1 .074 1.106l-.347 1.292q-.324 1.191-1.316 1.052a6.4 6.4 0 0 0-.995-.073 4.45 4.45 0 0 0-2.27.57 4.1 4.1 0 0 0-1.553 1.617h3.823q.82-.002 1.119.297a.93.93 0 0 1 .173.895 1.56 1.56 0 0 1-.447.918c-.31.207-.682.3-1.053.263h-4.21c-.047.398-.03.8.05 1.192h3.602q.82 0 1.119.31a.93.93 0 0 1 .173.882c-.04.348-.198.672-.447.919-.31.208-.682.3-1.053.263h-2.607a4.17 4.17 0 0 0 1.579 1.54 4.4 4.4 0 0 0 2.163.547q.51 0 1.018-.074c.357-.077.73-.025 1.053.148a1.28 1.28 0 0 1 .526.918l.247 1.316c.093.36.044.743-.136 1.068a1.27 1.27 0 0 1-.906.545 10 10 0 0 1-1.89.174 9.6 9.6 0 0 1-2.991-.448 8.4 8.4 0 0 1-2.471-1.266 8.63 8.63 0 0 1-3.106-4.47h-.62c-.414.044-.737-.059-.919-.266"
    }, null, -1)
  ])))
}
export default { render: render }